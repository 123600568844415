<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Pedidos</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus"
                    label="Status"
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="filterRegisters"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="8"
                md="8"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Pesquisa Rápida"
                        append-outer-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :item-class="verificarEntregaTurbo"
                    no-data-text="Nenhum registro encontrado"
                    :footer-props="{
                        'items-per-page-text':'Registros por Página',
                        'items-per-page-all-text':'Todos',
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:item.id="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a v-bind:href="item.customerUrl" v-on="on" target="_blank">
                                    {{ item.id }}
                                </a>
                            </template>
                            <span>Link Cliente</span>
                        </v-tooltip>
                    </template>

                    <template 
                        v-if="userLoggedGetters.usuarioInterno == 1"
                        v-slot:item.statusDescricao="{ item }"
                    >
                        <v-chip
                            style="font-size: 12px !important;"
                            small
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescricao }}
                        </v-chip>
                    </template>
                    <template 
                        v-else
                        v-slot:item.descricaoStatusCliente="{ item }"
                    >
                        <v-chip
                            style="font-size: 12px !important;"
                            small
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.descricaoStatusCliente }}
                        </v-chip>
                    </template>
                    <template 
                        v-slot:item.idTransportadora="{ item }"
                    >
                        <v-tooltip
                            left
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon       
                                    v-if="item.idTransportadora == null || item.idTransportadora == undefined || item.idTransportadora == '' || item.idTransportadora == '0'"
                                    v-on="on"
                                    small
                                    color="var(--color__warning)"
                                >
                                    mdi-alert
                                </v-icon>
                            </template>
                            <span>Pedido sem transportadora definida</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'carrinho'"
                            :showButtons="{
                                validar: item.status == 1 && userLoggedGetters.perfil.aprovarPedido == 1 ? true : false,
                                voltarPedido: item.status == 5 && userLoggedGetters.usuarioInterno == 1 ? true : false,
                                consultarLog:  userLoggedGetters.usuarioInterno == 1 ? true : false,
                                consultarAnexos:  userLoggedGetters.usuarioInterno == 1 && item.status >= 3 ? true : false,
                                cancelarPedido: podeCancelarPedido(item),
                                copiar: true
                            }"
                            @confirmValidar="confirmValidar"
                            @confirmVoltarPedido="confirmVoltarPedido"
                            @consultarLog="consultarLog"
                            @consultarAnexos="consultarAnexos"
                            @confirmCancelPedido="confirmCancelPedido"
                            @copiar="copiar"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        
		<v-dialog
			v-model="showLog"
			transition="dialog-top-transition"
			persistent
			width="90%"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Log do Pedido
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
					<v-data-table
                        :headers="headerLog"
                        :items="listLog"
                        class="elevation-1"
                        no-data-text="Não há log"
                        :footer-props="{
                            'items-per-page-text':'Registros por Página',
                            'items-per-page-all-text':'Todos',
                            pageText: '{0}-{1} de {2}'
                        }"
                    >
                    </v-data-table>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">

						<v-btn
							class="mx-2"
							outlined
							@click="showLog = false"
						>
							OK
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
        
		<v-dialog
			v-model="showAnexos"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Anexos
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
                    <v-simple-table
                        fixed-header
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Tipo
                                    </th>
                                    <th class="text-left">
                                        Nome
                                    </th>
                                    <th class="text-left">
                                        Criado Em
                                    </th>
                                    <th class="text-left">
                                        Criado Por
                                    </th>
                                    <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in listAnexos" :key="index"
                                >
                                    <td class="text-left">{{ item.tipo }}</td>
                                    <td class="text-left">{{ item.nome }}</td>
                                    <td class="text-left">{{ item.dataCadastroFormatted }}</td>
                                    <td class="text-left">{{ item.nomeUsuarioCadastro }}</td>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip 
                                                    link 
                                                    color="var(--color__main)"
                                                    style="color: white;"
                                                    v-on="on"
                                                    small 
                                                    @click="downloadFile(item.id, item.nome)"
                                                >
                                                    Download
                                                </v-chip>
                                            </template>
                                            <span>Clique para Download [{{ item.nome }}]</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">

						<v-btn
							class="mx-2"
							outlined
							@click="showAnexos = false"
						>
							Voltar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TIPO_DOCUMENTO, TIPO_ANEXO } from "@/utilities/Enums";
    import { orderBy } from '@/utilities/Utils';

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: '',
                statusSelected: null
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [],

            options: { rowsPerPage: 10, page: 1 },

            statusId: 0,
            listPedido: [],
            listPedidoFiltered: [],
            listStatus: [],
            
            listLog: [],
            showLog: false,
            headerLog: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Criado Em", value: "dataCadastroFormatted", sortable: true },
                { text: "Criado Por", value: "nomeUsuarioCadastro", sortable: true },
                { text: "Tipo", value: "tipoLogDescricao", sortable: true },
                { text: "Descrição", value: "descricao", sortable: true }
            ],

            listAnexos: [],
            showAnexos: false,
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listPedidoFiltered.filter((pedidoFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dataCadastroFormatted = pedidoFilter.dataCadastroFormatted.toLowerCase().match(filter)
                    var nomeUsuarioCadastro = pedidoFilter.nomeUsuarioCadastro.toLowerCase().match(filter)
                    var marcaDescricao = pedidoFilter.marcaDescricao.toLowerCase().match(filter)
                    var tipoClienteDescricao = pedidoFilter.tipoClienteDescricao.toLowerCase().match(filter)
                    var nomeClienteDistribuidor = pedidoFilter.nomeClienteDistribuidor != null && pedidoFilter.nomeClienteDistribuidor != undefined ? pedidoFilter.nomeClienteDistribuidor.toLowerCase().match(filter) : ''
                    var descontoUsuarioFormatted = pedidoFilter.descontoUsuarioFormatted.toLowerCase().match(filter)
                    var valorFreteFormatted = pedidoFilter.valorFreteFormatted.toLowerCase().match(filter)
                    var valorTotalBrutoFormatted = pedidoFilter.valorTotalBrutoFormatted.toLowerCase().match(filter)
                    var statusDescricao = pedidoFilter.statusDescricao.toLowerCase().match(filter)
                    var descricaoStatusCliente = pedidoFilter.descricaoStatusCliente != null && pedidoFilter.descricaoStatusCliente != undefined ? pedidoFilter.descricaoStatusCliente.toLowerCase().match(filter) : ''
                    var dataEntrega = pedidoFilter.dataEntrega != null && pedidoFilter.dataEntrega != undefined ? pedidoFilter.dataEntrega.toLowerCase().match(filter) : ''
                    var numeroPedidoCliente = pedidoFilter.numeroPedidoCliente != null && pedidoFilter.numeroPedidoCliente != undefined ? pedidoFilter.numeroPedidoCliente.toLowerCase().match(filter) : ''
                    var codigoPedidoTotvs = pedidoFilter.codigoPedidoTotvs != null && pedidoFilter.codigoPedidoTotvs != undefined ? pedidoFilter.codigoPedidoTotvs.toLowerCase().match(filter) : ''
                    var id = pedidoFilter.id.toString().match(filter);

                    if(dataCadastroFormatted != null) { return dataCadastroFormatted; }
                    else if(id != null) { return id; }
                    else if(marcaDescricao != null) { return marcaDescricao; }
                    else if(nomeUsuarioCadastro != null) { return nomeUsuarioCadastro; }
                    else if(tipoClienteDescricao != null) { return tipoClienteDescricao; }
                    else if(nomeClienteDistribuidor != null) { return nomeClienteDistribuidor; }
                    else if(descontoUsuarioFormatted != null) { return descontoUsuarioFormatted; }
                    else if(valorFreteFormatted != null) { return valorFreteFormatted; }
                    else if(valorTotalBrutoFormatted != null) { return valorTotalBrutoFormatted; }
                    else if(statusDescricao != null) { return statusDescricao; }
                    else if(descricaoStatusCliente != null) { return descricaoStatusCliente; }
                    else if(dataEntrega != null) { return dataEntrega; }
                    else if(numeroPedidoCliente != null) { return numeroPedidoCliente; }
                    else if(codigoPedidoTotvs != null) { return codigoPedidoTotvs; }
                });
            },

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            },

            temDistribuidorPai() {
                return this.userLoggedGetters.temDistribuidorPai == 1;
            },
        },

        methods: {

            podeCancelarPedido(item) {
                return this.userLoggedGetters.perfil.cancelarPedido == 1 && 
                       item.status != 99 &&
                       item.status != 98;
            },

            verificarEntregaTurbo(item) {

                if (item.entregaTurbo == 1) {
                    return 'entrega-turbo';
                }
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1: //ATIVO
                        color = 'var(--color__main)';
                        break;
                        
                    case 0: //INATIVO
                        color = 'var(--color__status_inactive)';
                        break;

                    case 2: //NEGADO
                        color = 'var(--color__red)';
                        break;

                    case 3: //AGUARDANDO INTEGRAÇÃO
                        color = 'var(--color__blue)';
                        break;

                    case 4: //PEDIDO INTEGRADO NO TOTVS
                        color = 'var(--color__main)';
                        break;

                    case 5: //ERRO NA INTEGRAÇÃO COM O TOTVS
                        color = 'var(--color__red)';
                        break;

                    case 6: //EM PROCESSAMENTO
                        color = 'var(--color__warning)';
                        break;

                    case 99: //CANCELADO
                    case 98: //CANCELADO
                        color = 'var(--color__red)';
                        break;
                
                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async getRegisters() {

                this.listPedido = await this.$store.dispatch("pedidoModule/List");
                this.listPedidoFiltered = [...this.listPedido];
                let listStatus = this.listPedido.map(ped => ped.statusDescricao);

                this.listStatus = listStatus.sort((a, b) => { return orderBy(a, b, 0, 0) });

                this.filterRegisters();
            },            

            async filterRegisters() {

                let statusDescricao = '';
                let listFiltered = [...this.listPedido];

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    statusDescricao = this.filter.statusSelected;
                    listFiltered = listFiltered.filter(ser => ser.statusDescricao == statusDescricao);
                }

                this.listPedidoFiltered = listFiltered;
            },

            confirmValidar(id) {
                this.$router.push({ name: 'pedidoValidacao', params: {id: id} });
            },

            copiar(id) {

                let item = this.listPedido.filter(ped => ped.id == id)[0];

                if (item != null && item != undefined) {

                    let url = item.customerUrl;

                    if (url) {
                        navigator.clipboard.writeText(url);
                        this.showToast("success", "Sucesso!", "Link copiado para a área de transferência!");
                    }
                }
            },

            confirmVoltarPedido(id) {

                this.statusId = 3;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Você irá VOLTAR esse PEDIDO para integração com o TOTVS, confirma sua decisão?',
                    methodConfirm: this.atualizarStatus,
                    params: id
                };

            },

            async consultarLog(id) {
                this.showLoading();

                this.listLog = await this.$store.dispatch("pedidoModule/GetLog", id);
                this.showLog = true;

                this.hideLoading();

            },

            async consultarAnexos(id) {

                this.listAnexos = []
                let listAnexos = await this.$store.dispatch("anexoModule/GetAll", { tipoAnexo: TIPO_ANEXO.PEDIDO.value, chave: id });

                let listOrdemCompra = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.ORDEM_COMPRA.value);
                if (listOrdemCompra != null && listOrdemCompra != undefined) {
                    
                    listOrdemCompra.forEach(item => {
                        
                        this.listAnexos.push({
                            id: item.id,
                            tipo: 'Ordem de Compra',
                            nome: item.nome,
                            dataCadastroFormatted: item.dataCadastroFormatted,
                            nomeUsuarioCadastro: item.nomeUsuarioCadastro
                        });
                    });
                }

                let listEvidenciaPreco = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.EVIDENCIA_PRECO.value);
                if (listEvidenciaPreco != null && listEvidenciaPreco != undefined) {
                    
                    listEvidenciaPreco.forEach(item => {
                        
                        this.listAnexos.push({
                            id: item.id,
                            tipo: 'Evidência do Preço',
                            nome: item.nome,
                            dataCadastroFormatted: item.dataCadastroFormatted,
                            nomeUsuarioCadastro: item.nomeUsuarioCadastro
                        });
                    });
                }

                let listOutros = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.OUTROS.value);
                if (listOutros != null && listOutros != undefined) {
                    
                    listOutros.forEach(item => {
                        
                        this.listAnexos.push({
                            id: item.id,
                            tipo: 'Outros',
                            nome: item.nome,
                            dataCadastroFormatted: item.dataCadastroFormatted,
                            nomeUsuarioCadastro: item.nomeUsuarioCadastro
                        });
                    });
                }

                this.showAnexos = true;
            },

            async downloadFile(id, nomeArquivo) {

                if (id != null && id != undefined && id != 0) {

                    this.$spTechApi.get(`anexos/download-file/${id}`, {
                            responseType: "blob",
                        })
                        .then((response) => {

                            var blob = new Blob([response.data], {
                                type: "application/octet-stream",
                            });

                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");

                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = url;
                            a.download = nomeArquivo;
                            a.click();
                            window.URL.revokeObjectURL(url);

                            this.hideLoading();
                        })
                        .catch(error => {
                            
                            let errorMessage = "";
                            if (error.response && error.response.status === 400) {
                                errorMessage = `Erro ao efetuar download do arquivo: [${error.response.data}]`;
                            }
                            else {
                                errorMessage = `Erro ao efetuar download do arquivo: [${error}]`;
                            }
                            
                            this.showToast("error", "Erro!", errorMessage);
                            this.hideLoading();
                        }
                    );
                }
            },

            confirmCancelPedido(id) {

                this.statusId = 99;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Você irá CANCELAR esse PEDIDO, confirma sua decisão?',
                    methodConfirm: this.atualizarStatus,
                    params: id
                };

            },

            async atualizarStatus(id) {

                this.showLoading();

                let updateStatusRequest = {
                    id,
                    status: this.statusId,
                    descricao: ''
                }

                let result = await this.$store.dispatch("pedidoModule/AlterarStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Sucesso!", result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                await this.getRegisters();

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();

            if (this.isUsuarioInterno == true) {
                this.headers = [
                    { text: "ID", value: "id", sortable: true, size: "5%" },
                    { text: "Núm. Pedido", value: "codigoPedidoTotvs", sortable: true },
                    { text: "Núm Ped Cliente", value: "numeroPedidoCliente", sortable: true },
                    { text: "Criado em", value: "dataCadastroFormatted", sortable: true },
                    { text: "Criado Por", value: "nomeUsuarioCadastro", sortable: true },
                    { text: "Marca", value: "marcaDescricao", sortable: true },
                    { text: "Tipo Cliente", value: "tipoClienteDescricao", sortable: true },
                    { text: "Nome", value: "nomeClienteDistribuidor", sortable: true },
                    { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                    { text: "Data Entrega", value: "dataEntrega", sortable: true },
                    { text: "Transp.", value: "idTransportadora", sortable: false, align: "center" },
                    { text: "Status", value: "statusDescricao", sortable: true },
                    { text: "Ações", value: "action", sortable: false, align: "center" }
                ]
            }
            else {
                if (this.temDistribuidorPai == true) {
                    this.headers = [
                        { text: "ID", value: "id", sortable: true, size: "5%" },
                        { text: "Núm Pedido", value: "codigoPedidoTotvs", sortable: true },
                        { text: "Núm Ped Cliente", value: "numeroPedidoCliente", sortable: true },
                        { text: "Criado em", value: "dataCadastroFormatted", sortable: true },
                        { text: "Nome", value: "nomeClienteDistribuidor", sortable: true },
                        { text: "Marca", value: "marcaDescricao", sortable: true },
                        { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                        { text: "Data Entrega", value: "dataEntrega", sortable: true },
                        { text: "Transp.", value: "idTransportadora", sortable: false, align: "center" },
                        { text: "Status", value: "descricaoStatusCliente", sortable: true },
                        { text: "Ações", value: "action", sortable: false, align: "center" }
                    ]
                }
                else {
                    
                    this.headers = [
                        { text: "ID", value: "id", sortable: true, size: "5%" },
                        { text: "Núm Pedido", value: "codigoPedidoTotvs", sortable: true },
                        { text: "Núm Ped Cliente", value: "numeroPedidoCliente", sortable: true },
                        { text: "Criado em", value: "dataCadastroFormatted", sortable: true },
                        { text: "Marca", value: "marcaDescricao", sortable: true },
                        { text: "Valor", value: "valorTotalBrutoFormatted", sortable: true, align: "right" },
                        { text: "Data Entrega", value: "dataEntrega", sortable: true },
                        { text: "Transp.", value: "idTransportadora", sortable: false, align: "center" },
                        { text: "Status", value: "descricaoStatusCliente", sortable: true },
                        { text: "Ações", value: "action", sortable: false, align: "center" }
                    ]
                }

            }

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>

<style>
    .entrega-turbo {
        background-color: var(--color__yellow) !important;
    }
</style>