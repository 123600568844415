var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7","lg":"10","md":"10","sm":"6"}},[_c('h3',[_vm._v("Pedidos")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('v-combobox',{attrs:{"items":_vm.listStatus,"label":"Status","item-text":"descricao","item-value":"id","clearable":"","outlined":""},on:{"change":_vm.filterRegisters},model:{value:(_vm.filter.statusSelected),callback:function ($$v) {_vm.$set(_vm.filter, "statusSelected", $$v)},expression:"filter.statusSelected"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"8","md":"8","sm":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":"Pesquisa Rápida","append-outer-icon":"mdi mdi-magnify","outlined":"","single-line":""},on:{"click":function($event){_vm.options.page=1;},"click:append-outer":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"item-class":_vm.verificarEntregaTurbo,"no-data-text":"Nenhum registro encontrado","footer-props":{
                        'items-per-page-text':'Registros por Página',
                        'items-per-page-all-text':'Todos',
                        pageText: '{0}-{1} de {2}'
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('a',_vm._g({attrs:{"href":item.customerUrl,"target":"_blank"}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',[_vm._v("Link Cliente")])])]}},(_vm.userLoggedGetters.usuarioInterno == 1)?{key:"item.statusDescricao",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-size":"12px !important"},attrs:{"small":"","color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescricao)+" ")])]}}:{key:"item.descricaoStatusCliente",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-size":"12px !important"},attrs:{"small":"","color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.descricaoStatusCliente)+" ")])]}},{key:"item.idTransportadora",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(item.idTransportadora == null || item.idTransportadora == undefined || item.idTransportadora == '' || item.idTransportadora == '0')?_c('v-icon',_vm._g({attrs:{"small":"","color":"var(--color__warning)"}},on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Pedido sem transportadora definida")])])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'carrinho',"showButtons":{
                                validar: item.status == 1 && _vm.userLoggedGetters.perfil.aprovarPedido == 1 ? true : false,
                                voltarPedido: item.status == 5 && _vm.userLoggedGetters.usuarioInterno == 1 ? true : false,
                                consultarLog:  _vm.userLoggedGetters.usuarioInterno == 1 ? true : false,
                                consultarAnexos:  _vm.userLoggedGetters.usuarioInterno == 1 && item.status >= 3 ? true : false,
                                cancelarPedido: _vm.podeCancelarPedido(item),
                                copiar: true
                            }},on:{"confirmValidar":_vm.confirmValidar,"confirmVoltarPedido":_vm.confirmVoltarPedido,"consultarLog":_vm.consultarLog,"consultarAnexos":_vm.consultarAnexos,"confirmCancelPedido":_vm.confirmCancelPedido,"copiar":_vm.copiar}})]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","width":"90%","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showLog),callback:function ($$v) {_vm.showLog=$$v},expression:"showLog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Log do Pedido ")]),_c('v-card-text',{staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerLog,"items":_vm.listLog,"no-data-text":"Não há log","footer-props":{
                            'items-per-page-text':'Registros por Página',
                            'items-per-page-all-text':'Todos',
                            pageText: '{0}-{1} de {2}'
                        }}})],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){_vm.showLog = false}}},[_vm._v(" OK ")])],1)])],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","width":"900","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showAnexos),callback:function ($$v) {_vm.showAnexos=$$v},expression:"showAnexos"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Anexos ")]),_c('v-card-text',{staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Tipo ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Nome ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Criado Em ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Criado Por ")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.listAnexos),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.tipo))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nome))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.dataCadastroFormatted))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nomeUsuarioCadastro))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-chip',_vm._g({staticStyle:{"color":"white"},attrs:{"link":"","color":"var(--color__main)","small":""},on:{"click":function($event){return _vm.downloadFile(item.id, item.nome)}}},on),[_vm._v(" Download ")])]}}],null,true)},[_c('span',[_vm._v("Clique para Download ["+_vm._s(item.nome)+"]")])])],1)])}),0)]},proxy:true}])})],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){_vm.showAnexos = false}}},[_vm._v(" Voltar ")])],1)])],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }